<template>
    <div>
        <div v-if="all_linked_products_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading linked products...
        </div>
        <div v-else>
            <div class="mb-2 d-flex justify-content-end">
                <b-button v-if="showButton" @click="onLinkRoles()" variant="success">+ Save Roles to Group</b-button>
            </div>

            <div>
                <webix-ui :config="ui" v-model="all_roles_by_group"></webix-ui>
                <div id="linkRolesToGroupTablePaging"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { productMethods, productComputed } from "@/state/helpers";
import { cloneDeep } from "lodash";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    props: {
        productIdsToSend: {
            required: true,
        },
    },
    data: () => ({ showButton: false }),
    computed: {
        ...productComputed,
        all_roles_by_group() {
            return cloneDeep(this.all_linked_roles);
        },
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "linkRolesToGroupTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                //hover: "datatableHover",
                minHeight: 60,
                // rowHeight: 40,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                // pager: {
                //     template: "{common.prev()} {common.pages()} {common.next()}",
                //     container: "linkRolesToGroupTablePaging",
                //     size: 20,
                //     group: 5,
                // },
                columns: [
                    {
                        id: "checkbox",
                        header: { content: "masterCheckbox" },
                        checkValue: "on",
                        uncheckValue: "off",
                        template: "{common.checkbox()}",
                        width: 40,
                    },
                    {
                        id: "display_name",
                        header: "Role",
                        tooltip: false,
                        // fillspace: 1,
                        sort: "text",
                        width: 300,
                    },
                    {
                        id: "description",
                        header: "Description",
                        tooltip: false,
                        fillspace: 1,
                    },
                ],
                ready: function () {
                    this.data.each(function (obj) {
                        //eslint-disable-next-line
                        if (obj.is_linked) {
                            this.getItem(obj.id).checkbox = "on";
                            this.refresh(obj.id);
                        }
                    });
                },
                on: {
                    onCheck: function (type, message, args) {
                        const getId = type;
                        const el = this.$scope.$parent;
                        //show the button because items have been clicked
                        el.showButton = true;

                        if (args == "on") {
                            el.addRolesToSaveToGroup(getId);
                        }

                        if (args == "off") {
                            el.removeFromRolesToSaveToGroup(getId);
                        }
                    },
                },
            };
            ui.pager = {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "linkRolesToGroupTablePaging",
                size: 20,
                group: 5,
            };
            return ui;
        },
    },
    methods: {
        ...productMethods,

        onLinkRoles() {
            Swal.fire({
                title: `Save Roles to this group`,
                // text: `${item.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    // item.on_collection = 0;

                    this.saveProductGroup({
                        id: this.$route.params.groupid,
                        name: this.selected_product_group_details.name,
                        description: this.selected_product_group_details.description,
                        display_material: this.selected_product_group_details.display_material,
                        linked_product_ids: this.productIdsToSend,
                        linked_role_ids: this.roleIds_to_save_to_group,
                    }).then(() => {
                        Swal.fire(`Roles saved to this group`, "", "success");
                        //refreshes the table on the screen

                        this.setAllLinkedProductsByGroup({ id: this.$route.params.groupid }).then(() => {
                            window.webix.$$("linkProductsToGroupTable").refresh();
                            // this.$emit("refreshTable");
                        });
                    });
                }
            });
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>
