<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <button type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="goBack()">
                            <i class="mdi mdi-arrow-left mr-1"></i> Go back
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="mb-4">
                        <h2 v-if="this.selected_product_group_details">Group: {{ this.selected_product_group_details.name }}</h2>
                    </div>
                    <div>
                        <b-tabs content-class="mt-3">
                            <b-tab @click="checkTab(1, tab1_key)" title="Link Products To Group" active>
                                <LinkProductsToGroupTable
                                    ref="childLinkProductsToGroupTable"
                                    @productIdsToSend="productIdsToSend"
                                    :key="calcKey(tab1_key)"
                                />
                            </b-tab>
                            <b-tab @click="checkTab(2, tab2_key)" title="Link Roles To Group">
                                <!-- <LinkRolesTable :productIds="products" /> -->
                                <LinkRolesTable
                                    :productIdsToSend="products"
                                    :refreshTable="refreshTable"
                                    ref="childLinkRolesTable"
                                    :key="calcKey(tab2_key)"
                                />
                            </b-tab>
                            <b-tab @click="checkTab(3, tab3_key)" title="Group Pricing">
                                <GroupPricingTable :productIdsToSend="products" :key="calcKey(tab3_key)" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LinkProductsToGroupTable from "./tables/linkProductsToGroupTable.vue";
import LinkRolesTable from "./tables/linkRolesTable.vue";
import GroupPricingTable from "./tables/groupPricingTable.vue";
import { productComputed, productMethods } from "@/state/helpers";

//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    components: { LinkProductsToGroupTable, LinkRolesTable, GroupPricingTable },
    data: () => ({
        products: [],
        tab1_key: 1,
        tab2_key: 10000,
        tab3_key: 20000,
    }),

    computed: {
        ...productComputed,
    },
    methods: {
        ...productMethods,
        calcKey(key) {
            if (key == this.tab1_key) {
                return this.tab1_key;
            }
            if (key == this.tab2_key) {
                return this.tab2_key;
            }
            if (key == this.tab3_key) {
                return this.tab3_key;
            }
        },
        refreshTable() {
            // window.webix.$$("linkRolesToGroupTablePaging").refresh();
            // this.$refs.childLinkRolesTable.initialComponentLoad();
        },
        productIdsToSend(products) {
            this.products = products;
        },
        checkTab(tabNo, key) {
            this.$nextTick(() => {
                if (key == this.tab1_key) {
                    this.tab1_key += 1;
                }
                if (key == this.tab2_key) {
                    this.tab2_key += 1;
                }
                if (key == this.tab3_key) {
                    this.tab3_key += 1;
                }
            });

            this.$nextTick(() => {
                if (tabNo == 1) {
                    window.webix.$$("linkProductsToGroupTable").refresh();
                }

                if (tabNo == 2) {
                    window.webix.$$("linkRolesToGroupTable").refresh();
                }

                if (tabNo == 3) {
                    window.webix.$$("groupPricingTable").refresh();
                }

                // // window.webix.$$("linkRolesToGroupTablePaging").refresh();
            });
        },
        closeModal() {
            this.$bvModal.hide("linkProductsToGroupModal");
        },
        goBack() {
            this.$router.push("/product-groups/");
        },
    },
    mounted() {
        // this.setAllLinkedProductsByGroup().then(() => {
        //     // this.hasMounted = true;
        // });
    },
};
</script>
<style></style>
