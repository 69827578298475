<template>
    <b-modal title="Pricing Modal" id="groupRolePricingModal" hide-footer>
        <b-overlay :show="busySaving">
            <h4>Group: {{ this.selected_product_group_details.name }}</h4>
            <!-- {{ selected_role_for_group_details }} -->
            <h5 class="mb-4">Role: {{ this.selected_role_for_group_details.display_name }}</h5>
            <hr />
            <b-form>
                <b-form-group id="example-input-group-1" label="Role:" label-for="example-input-1">
                    <b-form-input
                        disabled
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.role.$model"
                        :state="validateState('role')"
                        aria-describedby="input-1-live-feedback"
                        class="pointer"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group id="example-input-group-1" label="Percentile Change:" label-for="example-input-1">
                    <b-input-group append="%" class="">
                        <b-form-input
                            v-model="$v.form.percentile_change.$model"
                            :state="validateState('percentile_change')"
                            class="b-form-input"
                            type="number"
                            min="0"
                            max="100"
                        ></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                    >
                </b-form-group>
                <div class="modal-footer">
                    <b-button @click="onSubmit" variant="primary">Update Pricing</b-button>
                    <button type="button" class="btn btn-secondary" @click="closeModal()">Cancel</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { productComputed, productMethods } from "@/state/helpers";
//eslint-disable-next-line
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, minLength } from "vuelidate/lib/validators";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({
        busySaving: false,
        formSubmit: false,
        product_group_id: "",
        form: {
            id: -1,
            role_id: "",
            role: "",
            percentile_change: "",
        },
    }),
    validations: {
        form: {
            role: {
                required,
                minLength: minLength(2),
            },
            percentile_change: {
                required,
                minLength: minLength(1),
            },
        },
    },
    computed: {
        ...productComputed,
    },
    methods: {
        ...productMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        closeModal() {
            this.$bvModal.hide("groupRolePricingModal");
        },
        onSubmit() {
            //
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;

            Swal.fire({
                title: `Change pricing for ${(this.form.role = this.selected_role_for_group_details.display_name)}`,
                text: `Regarding product group: ${this.selected_product_group_details.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    // this.form.percentile_change = `-${this.form.percentile_change}`;

                    this.saveProductGroupRolePricing({
                        product_group_id: this.product_group_id,
                        linked_role_pricing: [this.form],
                    }).then(() => {
                        this.busySaving = false;
                        this.setAllLinkedProductsByGroup({ id: this.$route.params.groupid });
                        Swal.fire(`Role Pricing Changed`, "", "success");
                        this.closeModal();
                    });
                } else {
                    this.busySaving = false;
                }
            });
        },
    },
    mounted() {
        //eslint-disable-next-line
        console.log("selected group details", this.selected_role_for_group_details, this.selected_product_group_details);
        this.product_group_id = this.selected_product_group_details.id;
        this.form.role_id = this.selected_role_for_group_details.id;
        this.form.role = this.selected_role_for_group_details.display_name;
        this.form.percentile_change = this.selected_role_for_group_details.percentile_change;

        if (this.selected_role_for_group_details.percentile_change != 0) {
            this.form.id = this.selected_role_for_group_details.rule_id;
        }
    },
};
</script>
<style lang="scss" scoped>
.pointer {
    &:hover {
        cursor: no-drop;
    }
}
</style>
