<template>
    <div>
        <div v-if="all_linked_products_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading linked products...
        </div>
        <div v-else>
            <div v-if="all_roles_by_group.length > 0">
                <webix-ui :config="ui" v-model="all_roles_by_group"></webix-ui>
                <div id="groupPricingTablePaging"></div>
            </div>
            <div v-else class="ml-3">No Roles Linked yet</div>
        </div>
        <GroupRolePricingModal v-if="show_modal_pricing" />
    </div>
</template>

<script>
import { productMethods, productComputed } from "@/state/helpers";
import { cloneDeep } from "lodash";
//eslint-disable-next-line
import Swal from "sweetalert2";
import GroupRolePricingModal from "../modals/groupRolePricingModal.vue";

export default {
    props: {
        productIdsToSend: {
            required: true,
        },
    },
    components: { GroupRolePricingModal },
    data: () => ({ show_modal_pricing: false }),
    computed: {
        ...productComputed,
        all_roles_by_group() {
            return cloneDeep(this.group_pricing_roles);
        },
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "groupPricingTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                minHeight: 60,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "groupPricingTablePaging",
                    size: 20,
                    group: 5,
                },
                columns: [
                    {
                        id: "actions",
                        header: { text: "Actions", css: { "text-align": "center" } },
                        //eslint-disable-next-line
                        template: function (obj) {
                            if (obj.prices_array.length > 0) {
                                //
                            } else {
                                return `<div class="tableIcons">
                                <i v-b-tooltip.hover title="Set Pricing" class="mdi mdi-alpha-r-circle-outline viewAction"></i>
                                <i v-b-tooltip.hover title="Delete Pricing Rule" class="mdi mdi-trash-can change-visiblity  deleteAction"></i>

                            </div>`;
                            }

                            return `<div class="tableIcons">
                                <i v-b-tooltip.hover title="Set Pricing" class="mdi mdi-alpha-r-circle-outline viewAction"></i>
                                <i v-b-tooltip.hover title="Delete Pricing Rule" class="mdi mdi-trash-can deleteAction"></i>
                            </div>`;
                        },
                    },
                    {
                        id: "display_name",
                        header: "Role",
                        tooltip: false,
                        // fillspace: 1,
                        sort: "text",
                        width: 300,
                    },
                    {
                        id: "description",
                        header: "Description",
                        tooltip: false,
                        fillspace: 1,
                    },
                    {
                        id: "percentile_change",
                        header: "Percentile Change",
                        tooltip: false,
                        fillspace: 1,
                        template: function (obj) {
                            //eslint-disable-next-line
                            //eslint-disable-next-line
                            // console.log("obj", obj);
                            return obj.percentile_change;
                        },
                    },
                ],
                ready: function () {
                    window.webix.$$("linkProductsToGroupTable").refresh();
                },
                on: {},
                onClick: {
                    //eslint-disable-next-line
                    viewAction: function (id, e, trg) {
                        //eslint-disable-next-line
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent;

                        el.setSelectedRoleForGroupDetails(item);
                        el.showPricingModal();
                    },
                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        const el = this.$scope.$parent;
                        const item = this.getItem(e.row);

                        el.handleSwalDelete(item);
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...productMethods,
        showPricingModal() {
            this.show_modal_pricing = true;
            this.$nextTick(() => {
                this.$bvModal.show("groupRolePricingModal");
                this.$root.$once("bv::modal::hide", (event) => {
                    if (event.type == "hide" && event.componentId == "groupRolePricingModal") {
                        this.show_modal_pricing = false;
                    }
                });
            });
        },
        handleSwalDelete(item) {
            Swal.fire({
                title: `Are you sure you want to delete the pricing rule for
                            "${item.name}" ?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete!",
            }).then((result) => {
                if (result.value) {
                    this.ready = false;
                    this.deleteGroupPricing({ id: item.rule_id }).then(() => {
                        Swal.fire("Deleted", "Product Group Pricing", "success");
                        this.setAllLinkedProductsByGroup({ id: this.$route.params.groupid });
                    });
                }
            });
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
.change-visiblity {
    visibility: hidden;
}
</style>
