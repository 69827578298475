<template>
    <div>
        <div v-if="all_linked_products_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading linked products...
        </div>
        <div v-else>
            <b-overlay :show="show">
                <div class="mb-2 d-flex justify-content-end">
                    <b-button v-if="showButton" @click="onLinkProducts()" variant="success">+ Save Products to Group</b-button>
                </div>
                <div>
                    <webix-ui :config="ui" v-model="all_linked_products"></webix-ui>
                    <div id="linkProductsToGroupTablePaging"></div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { productMethods, productComputed } from "@/state/helpers";
//import { cloneDeep } from "lodash";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({
        productIdsToSend: [],
        productIdsInitialState: [],
        show: false,
        showButton: false,
    }),
    computed: {
        ...productComputed,
        /*all_products_by_group() {
            return this.all_linked_products;
        },*/
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "linkProductsToGroupTable",
                view: "datatable",
                // css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                //hover: "datatableHover",
                minHeight: 60,
                // rowHeight: 40,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "linkProductsToGroupTablePaging",
                    size: 20,
                    group: 5,
                },
                columns: [
                    {
                        id: "checkbox",
                        header: { content: "masterCheckbox", contentId: "mchb1" },
                        checkValue: "on",
                        uncheckValue: "off",
                        template: "{common.checkbox()}",
                        width: 40,
                    },
                    {
                        id: "name",
                        header: ["Product Name", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                        // width: 200,
                    },
                    {
                        id: "sku",
                        header: ["Product SKU", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                        // width: 200,
                    },
                ],
                ready: function () {
                    this.data.each(function (obj) {
                        //eslint-disable-next-line
                        if (obj.is_linked) {
                            this.getItem(obj.id).checkbox = "on";
                            this.refresh(obj.id);
                        }
                    });
                },
                on: {
                    //eslint-disable-next-line
                    onCheck: function (type, message, args) {
                        const getId = type;

                        el.showButton = true;

                        if (args == "on") {
                            let index = el.productIdsToSend.indexOf(getId);
                            if (index === -1) el.productIdsToSend.push(getId);
                        }

                        if (args == "off") {
                            let index = el.productIdsToSend.indexOf(getId);

                            el.productIdsToSend.splice(index, 1);
                        }
                    },
                },
            };
            //eslint-disable-next-line
            ui.pager = {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "linkProductsToGroupTablePaging",
                size: 20,
                group: 5,
            };

            return ui;
        },
    },
    methods: {
        ...productMethods,
        onLinkProducts() {
            Swal.fire({
                title: `Save Products to this group`,
                // text: `${item.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    // item.on_collection = 0;

                    this.saveProductGroup({
                        id: this.$route.params.groupid,
                        name: this.selected_product_group_details.name,
                        description: this.selected_product_group_details.description,
                        display_material: this.selected_product_group_details.display_material,
                        linked_product_ids: this.productIdsToSend,
                        linked_role_ids: this.roleIds_to_save_to_group,
                    }).then(() => {
                        //used to reset some values
                        this.initialComponentLoad();
                        Swal.fire(`Products saved to this group`, "", "success");
                        //refreshes the table on the screen
                        this.setAllLinkedProductsByGroup({ id: this.$route.params.groupid });
                    });
                }
            });
        },
        // check() {
        //     //eslint-disable-next-line
        //     // console.log("test", this.productIds_to_save_to_group);
        //     // const el = this.$scope.$parent;
        //     let t = window.webix.$$("linkProductsToGroupTable");

        //     //eslint-disable-next-line
        //     // console.log("overhere", t.data);
        //     this.$nextTick(() => {
        //         t.eachRow(function (id) {
        //             const el = this.$scope.$parent.$parent;
        //             let row = this.getItem(id);

        //             if (row.checkbox == "on") {
        //                 el.addToProductsToSaveToGroup(row.id);
        //                 //eslint-disable-next-line
        //                 console.log(el.productIds_to_save_to_group);
        //             } else {
        //                 //eslint-disable-next-line
        //                 el.removeFromProductsToSaveToGroup(row.id);
        //             }
        //         });
        //     });
        //     //eslint-disable-next-line
        //     console.log("productIds_to_save_to_group", this.productIds_to_save_to_group);
        // },
        initialComponentLoad() {
            this.productIdsToSend.length = 0;
            this.productIdsInitialState.length = 0;
            //laods the data into the productsIdsTosend proeprty
            const id = this.$route.params.groupid;

            this.setAllLinkedProductsByGroup({ id: id }).then(() => {
                // set the current is linked products
                for (const product of this.all_linked_products) {
                    if (product.is_linked == true) {
                        this.productIdsToSend.push(product.id);
                    }
                }
                this.$emit("productIdsToSend", this.productIdsToSend);
            });
        },
    },
    watch: {
        roleIds_to_save_to_group() {
            // this.initialComponentLoad();
        },
    },
    mounted() {
        this.initialComponentLoad();
    },
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>
